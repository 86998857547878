import React, {useEffect, useState} from 'react';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {FieldValues, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {
  getCurrencySymbol,
  getSupportedCountryInfo,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {twMerge} from 'tailwind-merge';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Components
import {ProgramFormFooter} from '@compt/pages/admin-pages/admin-learning-development/components/form-footer';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptRadioField} from '@compt/common/forms/compt-radio-field/compt-radio-field';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {BudgetPlanSidePanel} from './budget-plan-side-panel';
import {LearningDevelopmentStepper} from '../learning-development-stepper';

// Types
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {LnDProgramForms} from '@compt/pages/admin-pages/admin-learning-development/learning-development.types';
import {
  LearningDevelopmentProgram,
  ProgramFundingRule,
} from '@compt/types/learning-development/learning-development-program';
import {
  BudgetDistributionOption,
  EligibilityBreakdownOption,
} from '@compt/types/learning-development/eligibility-breakdown.types';

interface EligibilityBreakdownFormProps {
  existingData: LearningDevelopmentProgram;
}

interface EligibilityFieldValues extends FieldValues {
  eligibility_breakdown: EligibilityBreakdownOption;
  budget_renewal: {months: number; name: string};
  budget_distribution: BudgetDistributionOption;
}

export const EligibilityBreakdownForm = (props: EligibilityBreakdownFormProps) => {
  const {existingData} = props;
  const [budgetPlanOpen, setBudgetPlanOpen] = useState(false);

  const navigate = useNavigate();

  const {setStepStatusMap, stepStatusBase} = useFormStepperContext();

  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();

  const formMethods = useForm<EligibilityFieldValues>();
  const {dirtyFields} = formMethods.formState;
  const budgetPlanExists = existingData?.program_funding_rules
    ? existingData?.program_funding_rules?.length > 0
    : false;

  const watchedEligibility = formMethods.watch('eligibility_breakdown');
  const watchedBudgetRenewal = formMethods.watch('budget_renewal');
  const watchedBudgetDistribution = formMethods.watch('budget_distribution');
  const budgetAmountLabel = budgetAmountType.find((type) => type.id === watchedEligibility);

  const isFormValid = !!watchedEligibility && !!watchedBudgetRenewal && !!watchedBudgetDistribution;

  useEffect(() => {
    if (formMethods.formState.errors.budget_plan && budgetPlanExists) {
      formMethods.clearErrors('budget_plan');
    }

    if (formMethods.formState.errors.eligibility_form && isFormValid) {
      formMethods.clearErrors('eligibility_form');
    }
  }, [budgetPlanExists, isFormValid]);

  useEffect(() => {
    if (!existingData) return;
    const selectedBudgetRenewalOption = budgetRenewalOptions.find(
      (option) => option.months === existingData.budget_renewal_months,
    );

    formMethods.reset({
      eligibility_breakdown: existingData.eligibility,
      budget_renewal: selectedBudgetRenewalOption,
      budget_distribution:
        (existingData.budget_distribution as BudgetDistributionOption) ||
        BudgetDistributionOption.UPFRONT,
    });
  }, [existingData]);

  useEffect(() => {
    if (watchedEligibility !== EligibilityBreakdownOption.CASE_BY_CASE) return;
    formMethods.resetField('budget_renewal');
    formMethods.resetField('budget_distribution');
  }, [watchedEligibility]);

  function onFormUpdate(
    form: EligibilityFieldValues,
    buttonCallback: (updatedData: LearningDevelopmentProgram) => void,
  ) {
    if (!existingData) {
      triggerCustomToast('error', 'There was a problem updating your program', 'Please try again.');
      return;
    }

    function getBudgetDistributionValue() {
      if (
        form.eligibility_breakdown !== EligibilityBreakdownOption.CASE_BY_CASE &&
        form.budget_renewal?.months === 1
      ) {
        return BudgetDistributionOption.UPFRONT;
      }

      if (form.eligibility_breakdown === EligibilityBreakdownOption.CASE_BY_CASE) {
        return null;
      }

      if (form.eligibility_breakdown === EligibilityBreakdownOption.SHARED_BUDGET) {
        return BudgetDistributionOption.UPFRONT;
      }

      return form.budget_distribution;
    }

    const updatedSubmission = {
      id: existingData?.id,
      name: existingData?.name,
      company: {
        id: existingData?.company.id,
        name: existingData?.company.name,
      },
      eligibility: form.eligibility_breakdown,
      budget_renewal_months:
        form.eligibility_breakdown === EligibilityBreakdownOption.CASE_BY_CASE
          ? null
          : form.budget_renewal.months,
      budget_distribution: getBudgetDistributionValue(),
    };

    updateProgram(updatedSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      buttonCallback(results.data);
    });
  }

  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
    }));
  }

  function onNextClick() {
    // Do not move to next page if eligibility is not selected
    if (!watchedEligibility) {
      formMethods.setError('eligibility_breakdown', {
        type: 'custom',
        message: 'Eligibility breakdown must be set',
      });
      return;
    }
    formMethods.clearErrors('eligibility_breakdown');

    // Do not move to next page if budget plan is not defined
    if (watchedEligibility !== EligibilityBreakdownOption.CASE_BY_CASE && !budgetPlanExists) {
      formMethods.setError('budget_plan', {type: 'custom', message: 'Budget plan must be set'});
      return;
    }
    formMethods.clearErrors('budget_plan');

    if (Object.keys(dirtyFields).length === 0) {
      setStepStatusMap(() => ({
        ...stepStatusBase,
        [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.COMPLETE,
        [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onFormUpdate(form, () => {
        setStepStatusMap(() => ({
          ...stepStatusBase,
          [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.COMPLETE,
          [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0) {
      if (existingData?.is_draft) {
        triggerCustomToast('success', 'Program saved as a draft');
      }
      return;
    }

    formMethods.handleSubmit((form) =>
      onFormUpdate(form, () => {
        if (existingData?.is_draft) {
          triggerCustomToast('success', 'Program saved as a draft');
        }
      }),
    )();
  }

  function onBudgetPlanClick() {
    formMethods.clearErrors('budget_plan');
    formMethods.handleSubmit(
      // Triggers when form is valid
      (form) =>
        onFormUpdate(form, () => {
          setBudgetPlanOpen(true);
        }),
      // Triggers when form is invalid
      () => {
        formMethods.setError('eligibility_form', {
          type: 'custom',
          message: 'Other fields must be filled before creating budget plan',
        });
      },
    )();
  }

  function getGroupingNames(rule: ProgramFundingRule) {
    const userProperties = rule?.user_grouping_rule?.user_properties;

    if (!userProperties) {
      return <p className="body2">Everyone</p>;
    }

    const groupNames: string[] = [];
    Object.keys(userProperties).forEach((key) => {
      if (key === 'country') {
        groupNames.push(
          getSupportedCountryInfo(userProperties[key] as SupportedCountriesType).name,
        );
      } else {
        groupNames.push(userProperties[key]);
      }
    });

    return (
      <div>
        <p className="body2">{groupNames[0]}</p>
        <div className="flex">
          {groupNames[1] && <p className="body3 text-color-body2 mr-1">{groupNames[1]} </p>}
          {groupNames[2] && <p className="body3 text-color-body2">• {groupNames[2]}</p>}
        </div>
      </div>
    );
  }

  function getGroupingAmounts(
    rule: ProgramFundingRule,
    renewalType: {months: number; name: string},
    accrualType: BudgetDistributionOption,
  ) {
    const accrualText =
      accrualType === BudgetDistributionOption.MONTHLY_ACCRUAL
        ? `${getCurrencySymbol(rule.currency)} ${(
            parseFloat(rule.amount) / renewalType?.months
          ).toFixed(2)} per month`
        : null;

    return (
      <div>
        <p className="body2">
          {getCurrencySymbol(rule.currency)}
          {rule.amount} {rule.currency} {formMethods?.getValues('budget_renewal')?.name}
        </p>
        {renewalType?.months !== 1 && <p className="body3 text-color-body2">{accrualText}</p>}
      </div>
    );
  }

  return (
    <>
      <BudgetPlanSidePanel
        open={budgetPlanOpen}
        setOpen={setBudgetPlanOpen}
        eligibility={watchedEligibility as EligibilityBreakdownOption}
        existingData={existingData}
      />
      <div className="flex flex-col h-full pb-900 justify-between">
        <LearningDevelopmentStepper isFormDirty={Object.keys(dirtyFields).length > 0}>
          <ComptRadioField
            label="Eligibility breakdown"
            validation={{
              required: 'Eligibility breakdown is required',
            }}
            options={eligibilityOptions}
            id="eligibility_breakdown"
            name="eligibility_breakdown"
            register={formMethods.register}
            control={formMethods.control}
            errors={formMethods.formState.errors.eligibility_breakdown}
          />
          {formMethods.formState.errors.eligibility_form && (
            <p className="body3 text-stroke-critical">
              {formMethods.formState.errors.eligibility_form.message?.toString()}
            </p>
          )}
          {watchedEligibility && watchedEligibility !== EligibilityBreakdownOption.CASE_BY_CASE && (
            <>
              <ComptDropDownField
                name="budget_renewal"
                data-testid="budget-renewal-field"
                label="This budget renews:"
                subLabel="Your current fiscal year starts on January 1, 2024."
                options={budgetRenewalOptions}
                validation={{required: 'Budget renewal selection is required'}}
                getKey={(option) => option.months}
                getDisplayText={(option) => option.name}
                register={formMethods.register}
                control={formMethods.control}
                errors={formMethods.formState.errors.budget_renewal}
              />
              {/* Do not show when budget renewal option is Monthly OR for shared budget eligibility*/}
              {watchedBudgetRenewal &&
                watchedBudgetRenewal?.months !== 1 &&
                watchedEligibility &&
                watchedEligibility !== EligibilityBreakdownOption.SHARED_BUDGET && (
                  <ComptRadioField
                    label="Budget distribution"
                    validation={{
                      required: 'Budget distribution is required',
                    }}
                    options={budgetDistributionOptions}
                    id="budget_distribution"
                    data-testid="budget-distribution-field"
                    name="budget_distribution"
                    register={formMethods.register}
                    control={formMethods.control}
                    errors={formMethods.formState.errors.budget_distribution}
                  />
                )}
              <div className="flex">
                <label className="flex items-center body2 text-color-body1 mb-4">
                  Plan your budget
                </label>
                <p className="text-stroke-critical ml-0.5">*</p>
              </div>
              {!budgetPlanExists && (
                <div className="flex gap-x-2 items-center">
                  <p className="body1 text-color-body2">No budget created yet.</p>
                  {}
                  <button
                    className="flex items-center h-10 pr-5"
                    onClick={onBudgetPlanClick}
                    disabled={isUpdating}
                  >
                    <p
                      className={`body1 text-color-link cursor-pointer ${
                        (!isFormValid || isUpdating) && 'text-color-tertiary cursor-not-allowed'
                      }`}
                    >
                      Create plan
                    </p>
                  </button>
                </div>
              )}
              {budgetPlanExists && (
                <div className="flex items-start w-full gap-x-24">
                  <div className="w-3/4">
                    {props.existingData?.program_funding_rules &&
                      props.existingData?.program_funding_rules.map((rule, index) => {
                        getGroupingAmounts(rule, watchedBudgetRenewal, watchedBudgetDistribution);
                        return (
                          <div className="flex w-full border-b pb-2 pt-2" key={rule.id}>
                            <div className="w-1/2">
                              <p
                                className={twMerge(
                                  `hidden label3 text-color-body1 mb-2 ${index === 0 && 'block'}`,
                                )}
                              >
                                Group
                              </p>
                              {getGroupingNames(rule)}
                            </div>
                            <div>
                              <p
                                className={twMerge(
                                  `hidden label3 text-color-body1 mb-2 ${index === 0 && 'block'}`,
                                )}
                              >
                                {budgetAmountLabel?.label}
                              </p>
                              {getGroupingAmounts(
                                rule,
                                watchedBudgetRenewal,
                                watchedBudgetDistribution,
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <button onClick={onBudgetPlanClick} disabled={isUpdating}>
                    <p className="body2 text-color-link cursor-pointer pt-1.5">Edit</p>
                  </button>
                </div>
              )}
              {formMethods.formState.errors.budget_plan && (
                <p className="body3 text-stroke-critical">
                  {formMethods.formState.errors.budget_plan.message?.toString()}
                </p>
              )}
            </>
          )}
        </LearningDevelopmentStepper>
        <ProgramFormFooter className="flex flex-shrink-0">
          <ComptButton
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.BORDERLESS}
            onClick={() => {
              formMethods.reset();
              navigate('/manage-programs');
            }}
            disabled={isUpdating}
          >
            Cancel
          </ComptButton>
          <div className="flex gap-x-6">
            <ComptButton
              onClick={onPreviousClick}
              size={ComptButtonSize.LARGE}
              buttonType={ComptButtonType.BORDERLESS}
              disabled={isUpdating}
            >
              Previous
            </ComptButton>
            <ComptButton
              onClick={onSaveDraftClick}
              size={ComptButtonSize.LARGE}
              buttonType={ComptButtonType.SECONDARY}
              disabled={isUpdating}
            >
              Save draft
            </ComptButton>
            <ComptButton
              onClick={onNextClick}
              size={ComptButtonSize.LARGE}
              buttonType={ComptButtonType.PRIMARY}
              disabled={isUpdating}
            >
              Next
            </ComptButton>
          </div>
        </ProgramFormFooter>
      </div>
    </>
  );
};

export const budgetRenewalOptions = [
  {
    months: 1,
    name: 'Monthly',
  },
  {
    months: 3,
    name: 'Quarterly',
  },
  {
    months: 6,
    name: 'Semi-annually',
  },
  {
    months: 12,
    name: 'Yearly',
  },
];

export const budgetDistributionOptions = [
  {
    id: BudgetDistributionOption.UPFRONT,
    label: 'Upfront',
    description:
      'Employees will receive 100% funding, regardless of hire date or program start date',
  },
  {
    id: BudgetDistributionOption.UPFRONT_WITH_PRORATING,
    label: 'Upfront with prorating',
    description: 'Employees will receive funds adjusted based on their hire date',
  },
  {
    id: BudgetDistributionOption.MONTHLY_ACCRUAL,
    label: 'Monthly accrual',
    description: 'Funding is distributed in even increments every month',
  },
];

export const eligibilityOptions = [
  {
    id: EligibilityBreakdownOption.SHARED_BUDGET,
    label: 'Shared budget across a group',
    description: 'Employees will be able to access a single amount',
  },
  {
    id: EligibilityBreakdownOption.INDIVIDUAL_BUDGET,
    label: 'Individual budget per employee',
    description: 'Each employee will have their own funds to access',
  },
  {
    id: EligibilityBreakdownOption.CASE_BY_CASE,
    label: 'Reviewed on case-by-case basis',
    description: 'Employees can submit requests to be approved',
  },
];

const budgetAmountType = [
  {
    id: EligibilityBreakdownOption.SHARED_BUDGET,
    label: 'Amount per group',
  },
  {
    id: EligibilityBreakdownOption.INDIVIDUAL_BUDGET,
    label: 'Amount per individual',
  },
];
