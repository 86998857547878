import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAdminPreApprovalRequestQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';

// Types
import {EXPENSE_STATUS_INFO, ExpenseStatus} from '@compt/types/stipend-expense';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {LearningStatusFormats} from '../review-lnd.types';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ReviewPreApprovalRequestForm} from './review-pre-approval-request-form';
import {ReviewReimbursementForm} from './review-reimbursement-form';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';

interface ReviewRequestSidePanelProps {
  requestId: string | null;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  requestIdList: number[] | undefined;
}

enum RequestTab {
  'PRE_APPROVAL',
  'REIMBURSEMENT',
}

export const ReviewRequestSidePanel = (props: ReviewRequestSidePanelProps) => {
  const {requestId, requestIdList} = props;
  const [activeTab, setActiveTab] = useState(RequestTab.PRE_APPROVAL);
  const [, setSearchParams] = useSearchParams();

  const session = useGetSessionQuery();

  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);
  const requestQuery = useGetAdminPreApprovalRequestQuery(
    {
      companyId: company.data?.id,
      requestId: requestId,
    },
    {skip: !company.data?.id || !requestId},
  );
  const request = requestQuery.data;

  // Set active tab to reimbursement form if data exists
  useEffect(() => {
    if (!request) return;

    if (request.expense) {
      setActiveTab(() => RequestTab.REIMBURSEMENT);
      return;
    }

    setActiveTab(() => RequestTab.PRE_APPROVAL);
  }, [request]);

  function getPreviousAndNextRequestId(
    requestId: string | null,
    requestIdList: number[] | undefined,
  ) {
    if (!requestId || !requestIdList) return;

    const currentRequestId = parseInt(requestId);
    const currentIndex = requestIdList.indexOf(currentRequestId);

    const prevRequestId = requestIdList[currentIndex - 1] || null;
    const nextRequestId = requestIdList[currentIndex + 1] || null;

    return {prevRequestId, nextRequestId};
  }

  function onPreviousClick() {
    const previousRequestId = getPreviousAndNextRequestId(requestId, requestIdList)?.prevRequestId;
    if (!previousRequestId) return;

    setSearchParams({request_id: `${previousRequestId}`});
  }

  function onNextClick() {
    const nextRequestId = getPreviousAndNextRequestId(requestId, requestIdList)?.nextRequestId;
    if (!nextRequestId) return;

    setSearchParams({request_id: `${nextRequestId}`});
  }

  function clearSelectedRequest() {
    props.setEditMode(false);
    setSearchParams({});
  }

  const RequestStatusPill = () => {
    if (!request) return null;

    const preApprovalStatusFormat = LearningStatusFormats[request.status as LearningRequestStatus];

    const reimbursementStatusFormat =
      EXPENSE_STATUS_INFO[request?.expense?.status as ExpenseStatus];

    if (reimbursementStatusFormat && activeTab === RequestTab.REIMBURSEMENT) {
      return (
        <>
          <h2 className="text-color-body1">&nbsp; Reimbursement status</h2>
          <ComptPill
            className="ml-2"
            pillSize={ComptPillSize.MEDIUM}
            pillType={reimbursementStatusFormat?.statusStyle}
            data-tag="allowRowEvents"
          >
            {reimbursementStatusFormat?.statusName}
          </ComptPill>
        </>
      );
    }

    return (
      <>
        <h2 className="text-color-body1">&nbsp; Request status</h2>
        <ComptPill
          className="ml-2"
          pillSize={ComptPillSize.MEDIUM}
          pillType={preApprovalStatusFormat.statusStyle}
        >
          {request.status === 'PENDING_APPROVAL' ? 'Pending' : preApprovalStatusFormat.label}
        </ComptPill>
      </>
    );
  };

  return (
    <ComptSidePanel
      open={!!requestId}
      className="max-w-[920px]"
      data-testid="lnd-review-request-form"
    >
      <ComptSidePanel.Header
        title="Review submission"
        setOpen={clearSelectedRequest}
        headerIcon={{id: 'file-icon-blue'}}
        statusPill={
          <span className="flex">
            <RequestStatusPill />
          </span>
        }
      />
      <ComptLoadingIndicator isLoading={requestQuery.isLoading || requestQuery.isFetching}>
        <div className="flex pt-300 px-400 gap-x-6">
          <button
            className={`py-2 text-lg font-medium ${
              activeTab === RequestTab.PRE_APPROVAL
                ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab(RequestTab.PRE_APPROVAL)}
          >
            <p className="heading4">Request form</p>
          </button>
          {requestQuery?.data?.expense && (
            <button
              className={`py-2 text-lg font-medium ${
                activeTab === RequestTab.REIMBURSEMENT
                  ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab(RequestTab.REIMBURSEMENT)}
            >
              <p className="heading4">Reimbursement form</p>
            </button>
          )}
        </div>
        {activeTab === RequestTab.PRE_APPROVAL && (
          <ReviewPreApprovalRequestForm
            request={request}
            editMode={props.editMode}
            setEditMode={props.setEditMode}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            previousDisabled={!getPreviousAndNextRequestId(requestId, requestIdList)?.prevRequestId}
            nextDisabled={!getPreviousAndNextRequestId(requestId, requestIdList)?.nextRequestId}
          />
        )}
        {activeTab === RequestTab.REIMBURSEMENT && (
          <ReviewReimbursementForm
            request={request}
            editMode={props.editMode}
            setEditMode={props.setEditMode}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            previousDisabled={!getPreviousAndNextRequestId(requestId, requestIdList)?.prevRequestId}
            nextDisabled={!getPreviousAndNextRequestId(requestId, requestIdList)?.nextRequestId}
          />
        )}
      </ComptLoadingIndicator>
    </ComptSidePanel>
  );
};
