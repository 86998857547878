import React, {useContext, useEffect} from 'react';

// Hooks and methods
import {FieldValues, UseFormReturn} from 'react-hook-form';
import toast from 'react-hot-toast';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';

// Types
import {DateString} from '@compt/types/common/date-string';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {
  LearningDevelopmentProgram,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';

// Components
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {RequestFormContent} from './request-form-content';
import {ProgramSummary} from '@compt/types/learning-development/common-types';
import {
  EmployeePreApprovalRequest,
  getCustomFieldURLKey,
} from '@compt/types/learning-development/pre-approval-request';

export interface RequestFormProps {
  id: string;
  title: string;
  subtitle?: string;
  requestType: RequestType;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit: (payload: RequestFormFieldValues) => void;
  isPreview?: boolean;
  mutationLoading?: boolean;
  userId?: number;
  companyId?: number;
  userCountryCode?: SupportedCountriesType;
  'data-testid'?: string;
  formMethods: UseFormReturn<RequestFormFieldValues, any, undefined>;
  program: ProgramSummary | LearningDevelopmentProgram;
  preApprovalRequest?: EmployeePreApprovalRequest;
}

export interface RequestFormFieldValues extends FieldValues {
  receipt_key: string;
  vendor: string;
  expected_completion_date: DateString;
  description: string;
  amount: number;
}

export const RequestFormSidePanel = (props: RequestFormProps) => {
  const {mutationLoading, open, setOpen, formMethods} = props;
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);
  // TODO: get Program data in COMPT-5697 (data structure from COMPT-5533).
  // const sortedRequestFields = requestFields.sort((a, b) => a.sequence - b.sequence);

  // Remove any open toasts
  useEffect(() => {
    if (open) {
      toast.remove();
    }
  }, [open]);

  const receiptUrl: string =
    props.requestType === RequestType.PRE_APPROVAL && props.preApprovalRequest?.request_data
      ? (
          props.preApprovalRequest.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
        ).toString()
      : '';

  return (
    <ComptSidePanel open={props.open} className="max-w-[920px]" data-testid={props['data-testid']}>
      <ComptSidePanel.Header
        title={props.title}
        subtitle={props.subtitle}
        setOpen={() => setOpen(false)}
        headerIcon={{id: 'file-icon-blue'}}
        formMethods={formMethods}
      />
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col">
          <form className="grow" onSubmit={formMethods.handleSubmit(props.onSubmit)}>
            <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex justify-center bg-[#FAF6F1]">
                  <div
                    className={`
                      flex flex-col w-full sm:w-[480px] items-end p-6
                    `}
                  >
                    {/* TODO: attach correct domain to receipt field in COMPT-5729 */}
                    <ComptReceiptFormCarousel
                      formMethods={formMethods}
                      userId={props.userId}
                      receiptLabel="Upload document"
                      subLabel="Please upload supporting documents regarding the course."
                      initialSupportingDocs={[]}
                      readOnly={props.isPreview}
                      initialReceiptValue={receiptUrl}
                      receiptDomain="learning_and_dev"
                      supportingDocDomain="learning_and_dev_supporting_document"
                    />
                  </div>
                </div>
                <div className="w-full grow-[2] py-6 px-6">
                  <RequestFormContent
                    formMethods={formMethods}
                    // TODO: update with sortedRequestFields
                    requestFields={[]}
                    requestType={props.requestType}
                    isPreview={props.isPreview}
                    program={props.program}
                    preApprovalRequest={props.preApprovalRequest}
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
          {props.isPreview ? (
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              onClick={() => {
                setOpen(false);
                formMethods.reset();
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              Close
            </ComptButton>
          ) : (
            <>
              <ComptButton
                buttonType={ComptButtonType.PRIMARY}
                onClick={formMethods.handleSubmit((payload) => props.onSubmit(payload))}
                disabled={mutationLoading || receiptUploading}
                onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              >
                Request
              </ComptButton>
              <ComptButton
                buttonType={ComptButtonType.OUTLINED}
                onClick={() => {
                  setOpen(false);
                  setReceiptUploading(() => false);
                  formMethods.reset();
                }}
                disabled={mutationLoading}
              >
                Cancel
              </ComptButton>
            </>
          )}
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
