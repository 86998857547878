import React from 'react';

import {Editor} from '@tinymce/tinymce-react';
import {Controller, RegisterOptions, UseFormReturn} from 'react-hook-form';
import {ComptFormErrors} from '../compt-form-errors/compt-form-errors';

interface TinyMCEFieldProps {
  formMethods: UseFormReturn<any>;
  id?: string;
  name: string;
  label?: string;
  initialValue?: string;
  validation?: RegisterOptions;
  'data-testid'?: string;
}

export const ComptTinyMCEField = (props: TinyMCEFieldProps) => (
  <>
    <label
      htmlFor={props.id}
      data-testid={`${props.name}-label`}
      className="flex items-center body2 text-color-body1 mb-1"
    >
      {props.label}
      {props.validation?.required && <p className="text-stroke-critical ml-0.5">*</p>}
      {!props.validation?.required && (
        <p className="text-sm font-medium text-gray-400">&nbsp;(optional)</p>
      )}
    </label>
    <Controller
      control={props.formMethods.control}
      name={props.name}
      rules={props.validation}
      render={({field, field: {onChange}}) => (
        <Editor
          id={props.id}
          ref={field.ref}
          data-testid={props['data-testid']}
          apiKey="gjeskvfmeqn53utut92blq74x2rbqlx92kd93bwj6kp1wdoz"
          initialValue={props.formMethods.formState.defaultValues?.[props.name]}
          init={{
            menubar: false,
            toolbar: 'bold italic link bullist outdent indent removeformat',
            plugins: 'link lists paste',
            elementpath: false,
            default_link_target: '_blank',
            link_assume_external_targets: 'https',
            valid_elements: 'a[href|rel|title|target=_blank],em/i,li,p,span,strong/b,ul',
          }}
          onEditorChange={(newValue) => {
            onChange(newValue);
          }}
        />
      )}
    />
    {props.formMethods.formState.errors?.[props.name] && (
      <div className="mt-1.5">
        <ComptFormErrors
          errors={props.formMethods.formState.errors[props.name]}
          label={props.label}
          errorTestId={`${props['data-testid']}-error`}
        />
      </div>
    )}
  </>
);
