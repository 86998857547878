import React, {useMemo, useState} from 'react';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import DOMPurify from 'dompurify';

// Components
import {LearningDevelopmentStepper} from '../learning-development-stepper';
import {ProgramFormFooter} from '../form-footer';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ConfirmLndScheduleSidePanel} from './confirm-lnd-schedule-side-panel';

// Types
import {
  LearningDevelopmentProgram,
  ProgramReviewingConfiguration,
} from '@compt/types/learning-development/learning-development-program';
import {LnDProgramForms} from '../../learning-development.types';
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {
  budgetDistributionOptions,
  budgetRenewalOptions,
  eligibilityOptions,
} from '../eligibility-breakdown/eligibility-breakdown';
import {fundsExpirationStrategyOptions} from '../funds-expiration';

interface ReviewFormProps {
  existingData: LearningDevelopmentProgram | undefined;
}

export const ReviewForm = (props: ReviewFormProps) => {
  const {existingData} = props;

  const [openConfirmSidePanel, setOpenConfirmSidePanel] = useState(false);

  const {setStepStatusMap, stepStatusBase} = useFormStepperContext();

  const navigate = useNavigate();

  const formInformation = useMemo(() => {
    if (!existingData) return [];

    const eligibilityValue = eligibilityOptions.find(
      (option) => option.id === existingData.eligibility,
    );

    const budgetRenewalValue = budgetRenewalOptions.find(
      (option) => option.months === existingData.budget_renewal_months,
    );

    const budgetDistributionValue = budgetDistributionOptions.find(
      (option) => option.id === existingData.budget_distribution,
    );

    const fundsExpirationValue = fundsExpirationStrategyOptions.find(
      (option) => option.id === existingData.funds_expiration_strategy,
    );

    return [
      {
        label: 'Program name',
        value: existingData.name,
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Program description',
        value: existingData.description,
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Eligibility breakdown',
        value: eligibilityValue?.label,
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Budget renewal',
        value: budgetRenewalValue?.name ?? 'N/A',
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
          })),
      },

      {
        label: 'Budget distribution',
        value: budgetDistributionValue?.label ?? 'N/A',
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Funds expiration',
        value: fundsExpirationValue?.label,
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Number of days after',
        value: existingData.funds_expiration_days,
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Pre-approval request form',
        value: !!existingData.pre_approval_configuration && 'Created',
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Reimbursement request form',
        value: !!existingData.reimbursement_configuration && 'Created',
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.CURRENT,
          })),
      },
      {
        label: 'Approvers and reviewers',
        value: getNumberOfReviewers(existingData.program_reviewing_configuration),
        onClickEdit: () =>
          setStepStatusMap(() => ({
            ...stepStatusBase,
            [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
          })),
      },
    ];
  }, [existingData]);

  function getNumberOfReviewers(programReviewingConfig: ProgramReviewingConfiguration) {
    if (!programReviewingConfig) return 0;

    let sum =
      programReviewingConfig.primary_approvers?.length +
      programReviewingConfig.reimbursement_reviewers?.length +
      programReviewingConfig.secondary_approvers?.length;

    if (programReviewingConfig.use_employee_manager_as_primary_approver) sum += 1;
    if (programReviewingConfig.use_employee_manager_as_reimbursement_reviewer) sum += 1;
    if (programReviewingConfig.use_employee_manager_as_secondary_approver) sum += 1;

    return sum;
  }

  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
    }));
  }

  return (
    <>
      <ConfirmLndScheduleSidePanel
        open={openConfirmSidePanel}
        setOpen={setOpenConfirmSidePanel}
        existingData={existingData}
      />
      <div className="flex flex-col h-full pb-900 justify-between">
        <LearningDevelopmentStepper allStepsDisabled={!existingData?.is_draft}>
          <div className="max-w-2xl">
            <h3 className="mb-6">Review professional development program</h3>
            {formInformation?.length > 0 &&
              formInformation.map((info, index) => (
                <div key={index} className="flex justify-between py-2 border-b w-full">
                  <p className="body2">{info.label}</p>
                  <div className="flex justify-between w-1/2">
                    {info.label !== 'Program description' && (
                      <p className="body2 break-normal">{info.value}</p>
                    )}
                    {info.label === 'Program description' && typeof info.value === 'string' && (
                      <p
                        className="body2 inner-html line-clamp-4"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(info.value),
                        }}
                      />
                    )}
                    {existingData?.is_draft && (
                      <button onClick={info.onClickEdit}>
                        <ComptSvgIcon iconName="pencil-icon" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </LearningDevelopmentStepper>
        <ProgramFormFooter className="flex flex-shrink-0">
          <ComptButton
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.BORDERLESS}
            onClick={() => {
              navigate('/manage-programs');
            }}
          >
            {existingData?.is_draft ? 'Cancel' : 'Close'}
          </ComptButton>
          {existingData?.is_draft && (
            <div className="flex gap-x-6">
              <ComptButton
                onClick={onPreviousClick}
                size={ComptButtonSize.LARGE}
                buttonType={ComptButtonType.BORDERLESS}
              >
                Previous
              </ComptButton>
              <ComptButton
                onClick={() => {
                  navigate('/manage-programs');
                }}
                size={ComptButtonSize.LARGE}
                buttonType={ComptButtonType.SECONDARY}
              >
                Save draft
              </ComptButton>
              <ComptButton
                size={ComptButtonSize.LARGE}
                buttonType={ComptButtonType.PRIMARY}
                onClick={() => setOpenConfirmSidePanel(true)}
              >
                Schedule
              </ComptButton>
            </div>
          )}
        </ProgramFormFooter>
      </div>
    </>
  );
};
