import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {UserSession} from '@compt/types/account';
import {COMPT_API_BASE_PATH, KEEP_UNUSED_DATA_TIME} from '@compt/utils/consts';
import {SecurityHelpers} from '@compt/utils/security-helpers';

export enum ComptRTKTags {
  USER_SESSION = 'UserSession',
  STIPEND_ALLOTMENTS = 'StipendAllotments',
  STIPEND_EXPENSES = 'StipendExpenses',
  STIPEND_EXPENSE = 'StipendExpense',
  ADMIN_STIPENDS = 'AdminStipends',
  BUSINESS_EXPENSE_REPORT_LIST = 'BusinessExpenseReportList',
  BUSINESS_EXPENSE_REPORT = 'BusinessExpenseReport',
  BUSINESS_EXPENSE_LIST = 'BusinessExpenseList',
  ADMIN_BUSINESS_EXPENSE_LIST = 'AdminBusinessExpenseList',
  ADMIN_BUSINESS_EXPENSE_REPORT_LIST = 'AdminBusinessExpenseReportList',
  HISTORICAL_BUSINESS_EXPENSE_LIST = 'HistoricalBusinessExpenseList',
  TEAM_RECOGNITION_LIST = 'TeamRecognitionList',
  LEARNING_DEVELOPMENT_PROGRAM = 'LearningDevelopmentProgram',
  LEARNING_DEVELOPMENT_PROGRAM_LIST = 'LearningDevelopmentProgramList',
  LEARNING_DEVELOPMENT_ASSIGNABLE_USERS_LIST = 'LearningDevelopmentAssignableUsersList',
  LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST = 'LearningDevelopmentPreApprovalRequest',
  LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST = 'LearningDevelopmentPreApprovalRequestList',
  EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST = 'EmployeeLearningDevelopmentPreApprovalList',
  EMPLOYEE_LEARNING_DEVELOPMENT_PROGRAM_LIST = 'EmployeeLearningDevelopmentProgramList',
  CUSTOM_NOTIFICATIONS = 'CustomNotifications',
  TEAM_ROSTER_SYNC_ATTEMPT_LIST = 'TeamRosterSyncAttemptList',
  TEAM_ROSTER_SYNC_ATTEMPT = 'TeamRosterSyncAttempt',
  TEAM_ROSTER_SYNC_RESULT_LIST = 'TeamRosterSyncResultList',
  TEAM_ROSTER_SYNC_RESULT = 'TeamRosterSyncResult',
  VENDOR_DISCOVERY_LIST = 'VendorDiscoveryList',
}

const currentEnv = import.meta.env.MODE;

export const comptApiSlice = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(ComptRTKTags),
  keepUnusedDataFor: currentEnv === 'test' ? 0 : KEEP_UNUSED_DATA_TIME,
  baseQuery: fetchBaseQuery({
    baseUrl: COMPT_API_BASE_PATH,
    prepareHeaders: (headers) => {
      headers.set('X-CSRFToken', SecurityHelpers.getCSRFToken());
    },
  }),
  endpoints: (builder) => ({
    getSession: builder.query<UserSession, void>({
      providesTags: [ComptRTKTags.USER_SESSION],
      query: () => '/me',
    }),
  }), // Additional endpoints to be injected in their respective slices.
});

export const {useGetSessionQuery} = comptApiSlice;
