import {Allotment} from '@compt/types/allotments';
import {STATUS_KEYS} from '@compt/types/stipend-expenses/stipend-expenses';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {getStipendStatusCode} from '@compt/utils/stipend-helpers';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {SaveClaimFormFieldValues} from '@compt/pages/employee-stipends/components/compt-save-claim-side-panel/compt-save-claim-side-panel.controller';
import {CreateStipendBody, StipendMutation} from '@compt/app/services/api/stipend-expenses-slice';
import {DEFAULT_REIMBURSEMENT_INFO} from '@compt/constants';
import {NotificationMessagePost} from '@compt/types/notification';
import {FeatureFlags} from '@compt/utils/feature-flags-helper';
import {CreateTagBody, UserTagMutation} from '@compt/app/services/api/accounts-slice';
import {EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL} from '@compt/utils/analytics/types';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export interface AllotmentDropdownOption {
  id: string;
  allotment_dropdown: string;
}

export class MyStipendsController {
  scrollToCardOnSelect(
    selected: AllotmentDropdownOption | undefined | null,
    allotments?: Allotment[],
  ) {
    if (!selected) {
      return;
    }

    const element = document.getElementById(selected.id);
    if (element) {
      const isLastAllotment = selected.id === `allotment-${allotments?.[allotments.length - 1].id}`;
      element.scrollIntoView({behavior: 'smooth', block: isLastAllotment ? 'end' : 'center'});
    }
  }

  transformAllotmentToOption(allotments?: Allotment[]): AllotmentDropdownOption[] {
    if (!allotments) {
      return [];
    }

    return allotments?.map((allotment) => ({
      id: `allotment-${allotment.id}`,
      allotment_dropdown: `${allotment.cycle.name} (${formatCurrencyFromCountryCode(
        allotment.balance,
        undefined,
        allotment.currency,
      )} remaining, Expires
        ${formattedDate(allotment.last_date, DATE_FORMAT_OPTION['mm/dd/yyyy'])})`,
    }));
  }

  onSubmitClaim(
    payload: SaveClaimFormFieldValues,
    createStipend: StipendMutation,
    updateUserTag: UserTagMutation,
    setOpen: (isOpen: boolean) => void,
    setOpenSelectCategorySidePanel: (isOpen: boolean) => void,
    postToSlack: (messageObject: NotificationMessagePost) => void,
    postToMSTeams: (messageObject: NotificationMessagePost) => void,
    hasSlackToken?: boolean,
    customReimbursementInfo?: string,
  ) {
    const supportingDocList = [];

    for (const field in payload) {
      if (field.startsWith('supporting_doc_') && !!payload[field]) {
        supportingDocList.push(payload[field]);
      }
    }

    const expenseToSubmit: Partial<CreateStipendBody> = {
      ...payload,
      status: getStipendStatusCode(STATUS_KEYS.Open),
      receipt_key: payload.receipt_key,
      perk_category: payload.perk_category?.id,
      allotment_id: payload.allotment?.id,
      supporting_document_keys: supportingDocList,
    };

    const onDismissSubmitClaimToast = () => {
      if (window.features[FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY] !== 'enabled') {
        const helpScout = new HelpScout();
        helpScout.sendFeedback();

        const tagToUpdate: Partial<CreateTagBody> = {
          key: FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY,
          value: 'enabled',
        };

        updateUserTag(tagToUpdate).then((data) => {
          // Need to manually update the window feature
          window.features[FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY] = 'enabled';
        });
      }
    };

    createStipend(expenseToSubmit).then((result) => {
      if ('error' in result) {
        if ('data' in result.error) {
          triggerCustomToast(
            'error',
            'There was a problem submitting your claim.',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem submitting your claim');
        }
        console.error('Error submitting claim', result.error);
        return;
      }

      Analytics.sendEvent({
        action: EVENT_ACTION.SAVED_NEW_CLAIM,
        category: EVENT_CATEGORY.EMPLOYEE_MANAGED_EXPENSES,
        label: EVENT_LABEL.SAVED_PERK_CLAIM,
      });

      setOpen(false);

      // post to slack
      if (payload?.slack_message && hasSlackToken) {
        postToSlack({message: payload.slack_message});
      }

      // post to MS Teams
      if (payload?.ms_teams_message) {
        postToMSTeams({message: payload.ms_teams_message});
      }

      triggerCustomToast(
        'success',
        'Successfully submitted your claim',
        'Your claim has been submitted for review. Once your expenses are ' +
          "reviewed and processed by your company, you'll be reimbursed directly " +
          'in an upcoming paycheck.',
        customReimbursementInfo || DEFAULT_REIMBURSEMENT_INFO,
        'Add another',
        () => setOpenSelectCategorySidePanel(true),
        () => onDismissSubmitClaimToast(),
        40000, // 40 seconds
      );
    });
  }
}
