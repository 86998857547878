import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {
  formatCurrencyFromCountryCode,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Types
import {EXPENSE_STATUS_INFO, ExpenseStatus} from '@compt/types/stipend-expense';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {LearningStatusFormats} from './review-lnd.types';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {MAX_15_PAGE_LIMIT} from '@compt/constants';

export class ReviewLnDTableController {
  static getInitialFilterValues() {
    return {
      textSearch: [''],
      status: [],
    };
  }

  static getFilterConfiguration() {
    const filterConfiguration = {
      // TODO: implement column selection in search filter in COMPT-5862
      textSearch: {
        filterType: SearchFilter,
        label: 'Review Professional Development Table Search',
        options: [
          {
            id: 1,
            name: 'Review Professional Development Table Search',
            placeholder: 'Search by request ID, employee name, or program name',
          },
        ],
        nonCollapsible: true,
      },
    };
    return filterConfiguration;
  }

  static updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setQueryParams: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) return;

    setQueryParams((prevParams) => {
      const formattedFilter = {...prevParams};

      if (filterValues?.textSearch && filterValues?.textSearch[0]) {
        formattedFilter['search'] = filterValues.textSearch[0];
        setFiltersApplied(true);
      } else {
        delete formattedFilter['search'];
        setFiltersApplied(false);
      }

      formattedFilter.limit = MAX_15_PAGE_LIMIT;
      if (pagination?.page) {
        formattedFilter.offset = MAX_15_PAGE_LIMIT * (pagination.page - 1);
        formattedFilter.page = pagination.page;
      } else {
        formattedFilter.offset = 0;
        formattedFilter.page = 1;
      }

      if (ordering) {
        formattedFilter.ordering = ordering;
      }

      return formattedFilter;
    });
  }

  static formatFilterValuesOptions(
    filterValues?: FilterValues,
    pagination?: Pagination | null,
    ordering?: string | null,
  ): FormattedFilterObject {
    const formattedFilter: FormattedFilterObject = {};

    if (filterValues?.textSearch && filterValues?.textSearch[0]) {
      formattedFilter['search'] = filterValues.textSearch[0];
    }

    formattedFilter.limit = MAX_15_PAGE_LIMIT;
    if (pagination) {
      formattedFilter.offset = MAX_15_PAGE_LIMIT * Math.max(pagination.page - 1, 0);
      formattedFilter.page = pagination.page;
    } else {
      formattedFilter.page = 1;
      formattedFilter.offset = 0;
    }
    if (ordering) {
      formattedFilter.ordering = ordering;
    }

    return formattedFilter;
  }

  static getColumnDefinition(
    userCountryCode: SupportedCountriesType | undefined = 'US',
    onMenuClicked: (reviewData: PreApprovalRequest, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const columnDefinition = {
      request_number: {
        id: 'request_number',
        name: 'Request number',
        grow: 0.7,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.id}</p>
        ),
        order: 1,
        omit: false,
      },
      employee_name: {
        id: 'employee_name',
        name: 'Employee name',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.user?.full_name}</p>
        ),
        order: 2,
        omit: false,
      },
      amount: {
        id: 'amount',
        name: 'Amount',
        grow: 0.5,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(reviewData?.request_data?.['Amount'], userCountryCode)}
          </p>
        ),
        order: 3,
        omit: false,
      },
      program: {
        id: 'program',
        name: 'Program',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.program?.name}</p>
        ),
        order: 4,
      },
      last_updated: {
        id: 'last_updated',
        name: 'Last updated',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formattedDate(reviewData.modified_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => {
          const preApprovalStatusFormat =
            LearningStatusFormats[reviewData?.status as LearningRequestStatus];

          const reimbursementStatusFormat =
            EXPENSE_STATUS_INFO[reviewData?.expense?.status as ExpenseStatus];

          if (reimbursementStatusFormat) {
            return (
              <ComptPill
                pillType={reimbursementStatusFormat?.statusStyle}
                data-tag="allowRowEvents"
              >
                {reimbursementStatusFormat?.statusName}
              </ComptPill>
            );
          }

          return (
            <ComptPill pillType={preApprovalStatusFormat?.statusStyle} data-tag="allowRowEvents">
              {preApprovalStatusFormat?.label}
            </ComptPill>
          );
        },
        order: 6,
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (reviewData: PreApprovalRequest) => {
          const _onMenuClicked = (
            reviewData: PreApprovalRequest,
            action: 'VIEW' | 'EDIT' | 'DELETE',
          ) => {
            onMenuClicked(reviewData, action);
          };
          const actions: Array<SimpleAction<PreApprovalRequest>> = [
            {
              label: 'View',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'VIEW'),
            },
          ];

          if (reviewData.status === LearningRequestStatus.PENDING_APPROVAL) {
            actions.push({
              label: 'Edit',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'EDIT'),
            });
          }

          return <SimpleActionMenu relatedActionItem={reviewData} actions={actions} />;
        },
        grow: 0,
        order: 7,
        disableRemoval: true,
      },
    };

    return columnDefinition;
  }
}
