import React from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';

// Components
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTinyMCEField} from '@compt/common/forms/compt-tiny-mce-field/compt-tiny-mce-field';

// Types
import {ProgramOverviewFieldValues} from './program-overview.types';

interface ProgramOverviewFieldProps {
  formMethods: UseFormReturn<ProgramOverviewFieldValues>;
}

export const ProgramOverviewFields = (props: ProgramOverviewFieldProps) => (
  <div className="max-w-2xl">
    <ComptTextField
      name="name"
      label="Program name"
      data-testid="program-name-field"
      control={props.formMethods.control}
      register={props.formMethods.register}
      validation={{required: 'Program name is required'}}
      placeholder={'Give your program a name'}
      errors={props.formMethods.formState.errors.name}
      autoComplete="off"
    />
    <ComptTinyMCEField
      formMethods={props.formMethods}
      id="description"
      name="description"
      label="Program description"
      initialValue={''}
      validation={{required: 'Program description is required.'}}
    />
  </div>
);
