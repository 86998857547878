import React, {HTMLAttributes, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useCreateEmployeePreApprovalRequestMutation} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import DOMPurify from 'dompurify';

// Components
import {ComptSpentCard} from '@compt/common/compt-card/compt-spent-card';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {LnDFullDescriptionModal} from './lnd-full-description-modal';
import {
  RequestFormSidePanel,
  RequestFormFieldValues,
} from './request-form-side-panel/request-form-side-panel';

// Types
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {EmployeeLearningDevelopmentBudget} from '@compt/types/learning-development/employee-learning-development-budget';
import {RequestType} from '@compt/types/learning-development/learning-development-program';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';
import {PayloadLearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {Allotment} from '@compt/types/allotments';

export interface LearningCardProps extends HTMLAttributes<HTMLElement> {
  budget: EmployeeLearningDevelopmentBudget;
  userCountryCode?: SupportedCountriesType;
  disableOnClickWhenNoBalance?: boolean;
  onViewDetails?: (allotment: Allotment) => void;
}

export const LearningCard = (props: LearningCardProps) => {
  const {budget} = props;
  const [openDescription, setOpenDescription] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const isCaseByCase = budget.program.eligibility === EligibilityBreakdownOption.CASE_BY_CASE;

  const formMethods = useForm<RequestFormFieldValues>();

  const session = useGetSessionQuery();
  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  const [createPreApprovalRequest, {isLoading: isUpdating}] =
    useCreateEmployeePreApprovalRequestMutation();

  function onPreApprovalRequestSubmit(form: RequestFormFieldValues) {
    if (!company.data?.id || !budget) {
      triggerCustomToast('error', 'An error occurred submitting a pre-approval request');
      return;
    }

    const submission = {
      program_id: budget.program.id,
      status: PayloadLearningRequestStatus.PENDING_APPROVAL,
      request_data: {
        'Vendor name': form.vendor,
        Amount: form.amount,
        'Expected completion date': form.expected_completion_date,
        'Description and reason': form.description,
        'Upload document(s)': form.receipt_key,
      },
    };

    createPreApprovalRequest({body: submission, companyId: company.data.id}).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'An error occurred submitting a pre-approval request');
        return;
      }

      triggerCustomToast('success', 'Successfully submitted a pre-approval request!');
      setShowRequestForm(false);
      formMethods.reset();
      formMethods.reset({expected_completion_date: undefined});
    });
  }

  return (
    <>
      <RequestFormSidePanel
        id="lnd-request-form"
        title="Request form"
        subtitle={budget.program.name}
        open={showRequestForm}
        requestType={RequestType.PRE_APPROVAL}
        mutationLoading={isUpdating}
        setOpen={setShowRequestForm}
        onSubmit={onPreApprovalRequestSubmit}
        formMethods={formMethods}
        program={budget.program}
      />
      <LnDFullDescriptionModal
        title={budget.program.name}
        description={budget.program.description}
        open={openDescription}
        setOpen={setOpenDescription}
      />
      <div className="flex flex-col justify-between">
        <div className="flex flex-col">
          {/* TODO: route correct data to spent cards for other eligibility options in COMPT-5722 */}
          {!isCaseByCase && (
            <div className="mb-400">
              <ComptSpentCard
                balanceAmount={1000}
                amountSpent={10}
                currency="USD"
                userCountryCode={props.userCountryCode}
                isForLearning
              />
            </div>
          )}
          <div className="flex flex-row flex-wrap">
            {/* TODO: route correct data to spent cards for other eligibility options in COMPT-5722 */}
            {!isCaseByCase ? (
              <p className="label3 text-color-body1 pr-050 whitespace-nowrap">
                {budget.program.termination_date
                  ? `Expires ${formattedDate(
                      budget.program.termination_date,
                      DATE_FORMAT_OPTION['month dd yyyy'],
                    )}`
                  : 'No Expiration'}
              </p>
            ) : (
              <p className="label3 text-color-body1 pr-050 whitespace-nowrap">
                Requests reviewed case-by-case
              </p>
            )}
          </div>
          <p
            className="body3 line-clamp-[3] mt-2 mb-300 inner-html"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(budget.program.description),
            }}
          />
        </div>
        <div className="flex flex-row">
          <button className="label3 mr-4 text-color-link" onClick={() => setShowRequestForm(true)}>
            Submit new request
          </button>
          <button className="flex flex-row items-center" onClick={() => setOpenDescription(true)}>
            <p className="label3 text-color-body1">View details</p>
            <ComptSvgIcon
              iconName="chevron-right-icon-gray-700"
              svgProp={{width: '18px', height: '18px'}}
            />
          </button>
        </div>
      </div>
    </>
  );
};
