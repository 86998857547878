import React from 'react';
import {twMerge} from 'tailwind-merge';

interface ProgramFormFooterProps {
  children: React.ReactNode;
  className?: string;
}
export const ProgramFormFooter = (props: ProgramFormFooterProps) => (
  <div
    className={twMerge(`border-t w-full justify-between px-600 pb-600 pt-200 ${props.className}`)}
  >
    {props.children}
  </div>
);
