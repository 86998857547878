// The only valid statuses to send to API endpoints
export enum PayloadLearningRequestStatus {
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

// Statuses that are only received from API endpoints
export enum ResponseLearningRequestStatus {
  APPROVED_BY_PRIMARY_APPROVER = 'APPROVED_BY_PRIMARY_APPROVER',
  APPROVED_BY_SECONDARY_APPROVER = 'APPROVED_BY_SECONDARY_APPROVER',
  APPROVED_BY_REIMBURSEMENT_APPROVER = 'APPROVED_BY_REIMBURSEMENT_APPROVER',
}

export type LearningRequestStatus = PayloadLearningRequestStatus | ResponseLearningRequestStatus;

// Total list of statuses that can be displayed
export const LearningRequestStatus = {
  ...PayloadLearningRequestStatus,
  ...ResponseLearningRequestStatus,
};
