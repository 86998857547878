import React, {Fragment} from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {ComptFormControlMultiSelectBaseProps} from '@compt/types/form/compt-forms';
import {EnsureArray} from '@compt/types/form/headless-ui-helper-types';
import {debounce} from 'lodash';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

const DEFAULT_DEBOUNCE_DELAY = 300;

export interface AnchoredComboBoxOption {
  id: number;
  key: string;
  value: any;
  label: string;
}

export interface ComptSearchProps<TOptionType>
  extends ComptFormControlMultiSelectBaseProps<TOptionType> {
  clearOnSelect?: boolean;
  onQueryChanged: (query: string) => void;
  loading: boolean;
  getSecondaryText?: (option: TOptionType) => string;
  forceOpen?: boolean;
  /**
   * Specifies the key to compare by (e.g. email). Useful for when using objects.
   */
  by?: keyof TOptionType | ((a: TOptionType, b: TOptionType) => boolean);
  /**
   * If specified, this will be called right after the input.
   * @param selection
   */
  renderSelectionDisplay?: (
    selection: TOptionType[],
    onRemove: (toRemove: TOptionType) => void,
  ) => React.ReactNode;
  debounceDelay?: number;
  placeholder?: string;
  anchoredOption?: AnchoredComboBoxOption;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const ComptSearch = <DataType,>(props: ComptSearchProps<DataType>) => {
  const onSelectionChanged = (selection: DataType[]) => {
    if (props.clearOnSelect) {
      props.onQueryChanged('');
    }

    if (props.onChange) {
      props.onChange(selection as DataType[]);
    }
  };

  const handleRemove = (toRemove: DataType) => {
    const result = props.value || [];
    const keyToRemove = props.getKey(toRemove);
    onSelectionChanged(result.filter((data: DataType) => props.getKey(data) !== keyToRemove));
  };

  const comparator = (a: DataType, b: DataType) => {
    if (typeof props.by === 'function') {
      return props.by(a, b);
    }

    if (props.by) {
      return a[props.by] === b[props.by];
    }

    return a === b;
  };

  const onQueryChanged = (value: string) => {
    props.onQueryChanged(value);
  };

  const debouncedOnQueryChanged = debounce(
    onQueryChanged,
    props.debounceDelay ?? DEFAULT_DEBOUNCE_DELAY,
  );

  return (
    <>
      <Combobox<DataType>
        multiple
        nullable
        onChange={(selection) => {
          onSelectionChanged(selection as DataType[]);
        }}
        name={props.name}
        value={props.value as EnsureArray<DataType>}
        by={comparator}
        disabled={props.disabled}
      >
        {({open}) => (
          <>
            {props.label && (
              <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {props.label}
              </Combobox.Label>
            )}
            <div className="relative">
              <Combobox.Input
                ref={props.inputRef as React.Ref<HTMLInputElement>}
                autoComplete="off"
                className={`
               w-full h-600 rounded-lg py-1.5  pr-12 text-gray-900 border-0
               ring-1 ring-inset focus:ring-2
               sm:text-sm sm:leading-6
               ${props.disabled && 'bg-surface-disabled text-disabled-on-light'}
               ${
                 props.invalid
                   ? 'ring-stroke-critical focus:ring-stroke-critical'
                   : 'ring-stroke-tertiary focus:ring-stroke-focus'
               }`}
                onChange={(event) => debouncedOnQueryChanged(event.target.value)}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
              />
              {/* eslint-disable-next-line max-len */}
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ComptSvgIcon
                  iconName="chevron-down-icon"
                  className="mr-050"
                  svgProp={{width: '24px', height: '24px'}}
                />
              </Combobox.Button>

              {(open || props.forceOpen) && (
                <Transition
                  show={open || props.forceOpen}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options
                    // eslint-disable-next-line max-len
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    static
                  >
                    {props.anchoredOption && (
                      <Combobox.Option
                        key={props.anchoredOption.key}
                        value={props.anchoredOption.value}
                        className={({active}) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9 border-b',
                            active ? 'bg-gray-50' : 'text-gray-900',
                          )
                        }
                      >
                        {({selected}) => (
                          <>
                            <div className="flex">
                              <span
                                className={classNames('truncate', selected ? 'font-semibold' : '')}
                              >
                                {props.anchoredOption?.label}
                              </span>
                            </div>

                            {selected && (
                              <span
                                className={classNames(
                                  'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600',
                                )}
                              >
                                <ComptSvgIcon
                                  iconName="check-icon"
                                  labelHidden={false}
                                  ariaLabel="check-icon"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    )}
                    {!props.loading &&
                      props.options.map((data) => (
                        <Combobox.Option
                          key={props.getKey(data)}
                          value={data}
                          className={({active}) =>
                            classNames(
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active ? 'bg-gray-50' : 'text-gray-900',
                            )
                          }
                        >
                          {({selected}) => (
                            <>
                              <div className="flex">
                                <span
                                  className={classNames(
                                    'truncate',
                                    selected ? 'font-semibold' : '',
                                  )}
                                >
                                  {props.getDisplayText(data)}
                                </span>
                                {props.getSecondaryText && (
                                  <span className="ml-2 truncate text-color-tertiary">
                                    {props.getSecondaryText(data)}
                                  </span>
                                )}
                              </div>

                              {selected && (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600',
                                  )}
                                >
                                  <ComptSvgIcon
                                    iconName="check-icon"
                                    labelHidden={false}
                                    ariaLabel="check-icon"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    {props.loading && (
                      <Combobox.Option
                        value={null}
                        disabled
                        className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                      >
                        Loading...
                      </Combobox.Option>
                    )}
                  </Combobox.Options>
                </Transition>
              )}
            </div>
          </>
        )}
      </Combobox>
      {props.renderSelectionDisplay &&
        props.renderSelectionDisplay(props.value || [], handleRemove)}
    </>
  );
};
