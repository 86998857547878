import React, {HTMLAttributes} from 'react';
import {twMerge} from 'tailwind-merge';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';
import {ModalType} from '../compt-confirmation-modal/compt-confirmation.modal';

export interface ComptProgressStepperProps extends HTMLAttributes<HTMLElement> {
  steps: ComptProgressStep[];
  className?: string;
  allStepsDisabled?: boolean;
  isFormDirty?: boolean;
}

export interface ComptProgressStep {
  name: string;
  description?: string;
  status: StepStatus;
  statusBase?: StepStatus;
  onClick: () => void;
}

export enum StepStatus {
  LOCKED = 'locked',
  NEXT = 'next',
  CURRENT = 'current',
  COMPLETE = 'complete',
}

export const ComptProgressStepper = (props: ComptProgressStepperProps) => {
  const {steps, className} = props;

  const {modal, showModal} = useConfirmationModal(
    `You still have unsaved changes to your form.
    If you need to save your changes, please click "Save draft" or "Next".
    Leave without saving changes?`,
    'Unsaved changes',
    ModalType.PRIMARY,
    'Continue without saving',
    'Stay on page',
  );

  async function handleStepClick(onStepClick: () => void, isFormDirty: boolean) {
    if (!isFormDirty) {
      onStepClick();
      return;
    }

    const confirmPageChange = await showModal();
    if (confirmPageChange) onStepClick();
  }

  return (
    <>
      {modal}
      <nav aria-label="Progress">
        <ol className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={`${stepIdx !== steps.length - 1 ? 'pb-4' : ''} relative ${className}`}
            >
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    aria-hidden="true"
                    className={twMerge(
                      `absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 ${
                        step.statusBase === StepStatus.COMPLETE ? 'bg-color-link' : 'bg-gray-200'
                      }`,
                    )}
                  />
                ) : null}
                <button
                  onClick={() => handleStepClick(step.onClick, !!props.isFormDirty)}
                  disabled={step.status === StepStatus.LOCKED || props.allStepsDisabled}
                  className={`group relative flex items-center ${
                    (step.status === StepStatus.LOCKED || props.allStepsDisabled) &&
                    'cursor-not-allowed'
                  }`}
                >
                  <span className="flex h-9 items-center">
                    <span
                      className={twMerge(
                        `relative z-10 flex h-8 w-8 items-center
                                justify-center rounded-full bg-white
                                ${
                                  step.status === StepStatus.CURRENT &&
                                  'border-4 border-color-link border-opacity-20'
                                }`,
                      )}
                    >
                      <ComptSvgIcon
                        iconName={
                          step.status === StepStatus.COMPLETE
                            ? 'progress-stepper-check-icon'
                            : step.status === StepStatus.CURRENT
                            ? 'progress-stepper-current-icon'
                            : 'progress-stepper-upcoming-icon'
                        }
                      />
                    </span>
                  </span>
                  <span className="ml-3 flex min-w-0 flex-col">
                    <span
                      className={twMerge(
                        `label3 text-left ${
                          step.status === StepStatus.CURRENT
                            ? 'text-color-link'
                            : 'text-color-body1'
                        }`,
                      )}
                    >
                      {step.name}
                    </span>
                    {step.description && (
                      <span className={twMerge('body3 text-color-body2')}>{step.description}</span>
                    )}
                  </span>
                </button>
              </>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};
