import React, {useMemo, useState} from 'react';

// RTK queries
import {useDeleteLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';

// Types
import {ModalType} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';

// Components
import {ProgramCard} from './program-card';
import {Tooltip} from 'react-tooltip';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {CategoryIconList} from './category-icon-list';
import {ProgramDate} from './program-date';
import {SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ConfirmTerminationSidePanel} from './confirm-termination-side-panel';

interface LearningDevelopmentCardProps {
  program: LearningDevelopmentProgram;
}
export const LearningDevelopmentCard = (props: LearningDevelopmentCardProps) => {
  const {program} = props;

  const [openTerminationSidePanel, setOpenTerminationSidePanel] = useState(false);

  const isMobileView = useIsMobileView();
  const navigate = useNavigate();

  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    'Are you sure you want to delete this program draft? This action cannot be undone.',
    'Confirm delete',
    ModalType.DESTRUCTIVE,
    'Delete',
  );

  const [deleteLearningProgram, {isLoading: isDeleting}] =
    useDeleteLearningDevelopmentProgramMutation();

  const menuActions = useMemo(() => {
    const actions = [];

    if (program.is_draft) {
      const draftActions = [
        {
          label: 'Edit',
          onClick: () =>
            navigate(`/learning-development-form?form_type=edit&program_id=${program.id}`),
        },
        {
          label: 'Delete',
          textColor: 'text-red-600',
          onClick: onProgramDelete,
        },
      ];

      actions.push(...draftActions);
    }

    if (!program.is_draft) {
      actions.push({
        label: 'Terminate',
        textColor: 'text-red-600',
        onClick: () => setOpenTerminationSidePanel(true),
      });
    }

    return actions;
  }, [program]);

  async function onProgramDelete() {
    const confirmDelete = await showDeleteModal();
    if (!confirmDelete) return;

    deleteLearningProgram({companyId: program.company.id, programId: program.id});
  }

  return (
    <ProgramCard key={`program-card-${program.id}`}>
      {confirmDeleteModal}
      <ConfirmTerminationSidePanel
        open={openTerminationSidePanel}
        setOpen={setOpenTerminationSidePanel}
        programData={program}
      />
      <ComptLoadingIndicator isLoading={isDeleting}>
        <ProgramCard.Header className="flex justify-between">
          <div className="flex-none w-3/4">
            <Tooltip
              anchorSelect={`#program-title-${program.id}`}
              place="top"
              opacity={100}
              style={{borderRadius: '6px'}}
            >
              {program.name}
            </Tooltip>
            <h2 id={`program-title-${program.id}`} className="text-ellipsis truncate">
              {program.name}
            </h2>
            <p className="label3 text-color-body1">Professional development</p>
          </div>
          {!isMobileView && <StatusPill isDraft={program.is_draft} />}
          <SimpleActionMenu relatedActionItem={program} actions={menuActions} />
        </ProgramCard.Header>
        <ProgramCard.Content className="flex-grow flex-col justify-between">
          <div>
            <ProgramDate className="mb-4" label="Starts" date={program.start_date} />
            <ProgramDate label="Ends" date={program.termination_date} />
            <CategoryIconList programId={program.id} perkCategoryList={program.perk_categories} />
          </div>
          <div
            className="flex label3 text-color-link place-content-start cursor-pointer"
            onClick={() =>
              navigate(`/learning-development-form?form_type=edit&program_id=${program.id}`)
            }
          >
            <p className="mr-1">View details</p>
            <ComptSvgIcon className="flex-row pt-0 sm:pt-0" iconName="chevron-right-icon" />
          </div>
        </ProgramCard.Content>
      </ComptLoadingIndicator>
    </ProgramCard>
  );
};

const StatusPill = ({isDraft}: {isDraft: boolean}) => {
  if (!isDraft) {
    return (
      <ComptPill pillSize={ComptPillSize.SMALL} pillType="success">
        Active
      </ComptPill>
    );
  }

  return (
    <ComptPill pillSize={ComptPillSize.SMALL} pillType="gray">
      Draft
    </ComptPill>
  );
};
