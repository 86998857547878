import React, {useEffect, useLayoutEffect} from 'react';
import './App.css';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {ComptLayout} from './common/compt-layout/compt-layout.container';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {Toaster} from 'react-hot-toast';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

/**
 * Compt's app component. It wraps all the child components we display in our application within the
 * React Router <Outlet/> component.
 *
 * @see https://reactrouter.com/en/main/components/outlet
 */
export const App = () => {
  const session = useGetSessionQuery();
  const userData = session.data;
  const steps = userData?.onboarding_steps;
  const onboardingRequired = steps ? Object.values(steps).includes(false) : false;
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname, search} = location;
  const isMobileView = useIsMobileView();

  useEffect(() => {
    if (onboardingRequired && pathname !== '/employee-onboarding') {
      navigate('/employee-onboarding');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingRequired, pathname]);

  useEffect(() => {
    Analytics.triggerPageView(pathname, search);
  }, [pathname, search]);

  // Reset scroll position when switching between pages
  useLayoutEffect(() => {
    const scrollToElement = () => {
      const element = document.querySelector('.layout-main-content');
      if (element) {
        element.scrollTo(0, 0);
      }
    };

    scrollToElement();
  }, [pathname]);

  return (
    <>
      <Toaster
        toastOptions={{
          position: isMobileView ? 'bottom-center' : 'top-right',
          style: {pointerEvents: 'fill'},
        }}
      />
      <div className="compt-app">
        <ComptLayout onboardingRequired={onboardingRequired}>
          <Outlet />
        </ComptLayout>
      </div>
    </>
  );
};
