import React, {useState} from 'react';
import {LndDropDown} from './review-lnd-dropdown';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

const STATUS_OPTIONS = [
  {id: LearningRequestStatus.PENDING_APPROVAL, label: 'Ready for review'},
  {id: LearningRequestStatus.APPROVED, label: 'Approved'},
  {id: LearningRequestStatus.REJECTED, label: 'Rejected'},
  {id: LearningRequestStatus.APPROVED_BY_PRIMARY_APPROVER, label: 'Approved by primary approver'},
];

const FILTER_OPTIONS = [
  // {id: 'country', label: 'Country', testId: 'review-lnd-country-filter'},
  // {id: 'currency', label: 'Currency', testId: 'review-lnd-currency-filter'},
  // {id: 'department', label: 'Department', testId: 'review-lnd-department-filter'},
  {id: 'status', label: 'Status', testId: 'review-lnd-status-filter'},
  // {id: 'amount', label: 'Amount', testId: 'review-lnd-amount-filter'},
  // {id: 'receipt_date', label: 'Receipt date', testId: 'review-lnd-receipt-date-filter'},
  // {id: 'date_submitted', label: 'Date submitted', testId: 'review-lnd-date-submitted-filter'},
  // {id: 'stipend', label: 'Stipend', testId: 'review-lnd-stipend-filter'},
  // {id: 'category', label: 'Category', testId: 'review-lnd-category-filter'},
  // {id: 'taxability', label: 'Taxability', testId: 'review-lnd-taxability-filter'},
];

interface FilterSidebarProps {
  onHide: () => void;
  selectedStatuses: string[];
  setSelectedStatuses: (statuses: string[]) => void;
}

export const ReviewLndFilterSidebar: React.FC<FilterSidebarProps> = ({
  onHide,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);

  const handleStatusChange = (status: string) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen((prev) => !prev);
  };

  const handleClearAll = () => {
    setSelectedStatuses([]);
  };

  return (
    <div className="flex flex-col p-4 flex-grow">
      <div className="flex justify-between items-center">
        <p className="text-md text-color-body1 font-bold">Filter</p>
        {selectedStatuses?.length > 0 && (
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => handleClearAll()}
            size={ComptButtonSize.SMALL}
            className="py-1 px-0 min-w-[70px]"
          >
            Clear all
          </ComptButton>
        )}
      </div>
      <div className="flex flex-col mt-4 space-y-2">
        {FILTER_OPTIONS.map((filter) => (
          <React.Fragment key={filter.id}>
            <LndDropDown
              id={`review-lnd-${filter.id}-filter`}
              data-testid={filter.testId}
              value={{id: filter.id, label: filter.label}}
              onClick={filter.id === 'status' ? toggleStatusDropdown : () => {}}
              isSelected={isStatusDropdownOpen && filter.id === 'status'}
            />
            {isStatusDropdownOpen && filter.id === 'status' && (
              <div className="flex flex-col my-2 space-y-2 pl-2">
                {STATUS_OPTIONS.map((option) => (
                  <label key={option.id} className="flex items-center space-x-1">
                    <input
                      type="checkbox"
                      checked={selectedStatuses.includes(option.id)}
                      onChange={() => handleStatusChange(option.id)}
                      className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
                        focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
                        disabled:hover:bg-gray-400`}
                    />
                    <span className="text-sm text-gray-700">{option.label}</span>
                  </label>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <button className="mt-auto py-2 text-md text-left font-medium text-primary" onClick={onHide}>
        Hide filter
      </button>
    </div>
  );
};
